
/* #root {
  width: 100vw;
} */

body {
  margin: 0;
  padding: 0;
}
html {
	-webkit-text-size-adjust: none;
  touch-action: manipulation;
  --box-shadow:none !important; 
  -webkit-tap-highlight-color:transparent;
}

.App {
  text-align: center;
  /* background: linear-gradient(to bottom, #77b4ff,   white);   */
  font-family: 'Quicksand', sans-serif;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

canvas {
  position:absolute;
  top:0;
  left:0;
  width: 100%;
  height:100%;
  touch-action:none;
  outline: none !important;
}


.App-Contents {
  background-color: #ffffb7;

}

.signup-form iframe {
  border:none;
  width:100%;
  height:538px;
}

.footer {
  padding-top: 100px; 
  /* padding-bottom: 100px;  */
  height: 100px;
}

#my-canvas {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}


p {
  font-size: 1.5em;
  font-weight: 500;
}

.bg {
  /* position: absolute; */
  /* left: 50%; */
  /* transform: translate3d(-50%, 9.14634px, 0px); */
  /* transform-style: preserve-3d; */
  /* backface-visibility: hidden; */
  /* height: 100vh; */
  /* min-height: 1000px; */
  /* object-position: 50% 50%; */
  /* object-fit: cover; */
  /* width: 100%; */
  /* filter: none; */

  
  /* height: 500px; */
  background-size: cover;
    background-position: 50% 50%;
    position: relative;
    width: 100%;

}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.popup-screen-group {
  position: sticky;
  z-index: 100;
  top: 0;
}

/* .popup-screen {
  text-align: center;
  font-family: 'Quicksand', sans-serif;

  width: 100vw;
  height: 100vh;
  background-color: #ffffff6b;
  position: absolute;
  z-index: 1000;
} */

/* Fade-in effect for the background */


.popup-screen {
  text-align: center;
  font-family: 'Quicksand', sans-serif;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.42); /* Adjust opacity as needed */
  position: fixed; /* Change from absolute to fixed */
  top: 0; /* Ensure it starts from the top */
  left: 0; /* Ensure it starts from the left */
  right: 0; /* Ensure it covers the right */
  bottom: 0; /* Ensure it covers the bottom */
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.5s ease; /* Fade-in transition */
}

/* Show the popup when visible */
.popup-screen.visible {
  opacity: 1;
}

/* Popup box animation */
.popup-box {
  background-color: #426bad;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 328px; 
  height: auto;   
  margin-left: -200px;   
  margin-top: -200px;   
  border-radius: 34px;
  padding: 24px;
  box-shadow: 5px 10px #282c34a8;
  border: 10px solid #5177a4;
  border-top: 14px solid #a1b7df;
  transform: scale(0); /* Initial scale for pop-in */
}


/* Show the popup box when visible */
.popup-box.visible {
  opacity: 1; /* Make it visible */  
  transform: scale(0); /* Initial scale for pop-in */
  animation: popIn 0.2s forwards; /* Pop-in animation */
}

.popup-screen.hidden {
  visibility: hidden;
  height:0;
  width: 0;
}



.popup-button {
  width: 120px;
  height: 40px;
  padding-top: 14px;
  color: white;
  background-color: #54c8ff;
  border-left: 4px solid #282c34;
  border-bottom: 4px solid #8893a8;
  
  display: inline-block;
  cursor: pointer;
  font-weight: 700;    
  font-size: 1.2em;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 42px;
}

.popup-title {
  background-color: white;
  border-radius: 5px;
  padding: 14px;
  color: black;
  font-weight: 700;
  font-size: 26px;
  margin-top: 0;
  margin-bottom:8px;
  border-top: 2px solid #939393;
  border-left: 2px solid #939393;
}

.popup-message {
  margin-top: 0;
  font-weight: 700;
  font-size: 20px;
  padding: 1rem;
  text-align: left;
  border-top: 2px solid #939393;
  border-left: 2px solid #939393;
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  overflow: scroll;
}

.popup-message-loader {
  margin-top: 0;
  font-weight: 700;
  font-size: 20px;
  padding: 1rem;
  text-align: left;
  border-top: 2px solid #939393;
  border-left: 2px solid #939393;
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
  padding:0;
  background-color:#c2ecff;
  position: relative;
}


.manage-panel-avatar {
  width: 100%;
  max-width: 320px;
  position: relative;
}

.manage-panel-avatar img {
  width: 100%;
}
.manage-panel-avatar-layer {
  position: absolute;
  left: 0;
}

.manage-panel-attributes-left {
  display: inline-block;
  width:48%;    
  border: 1px solid black;
  border-radius: 4px;
  vertical-align: top;
  height: 100%;
  min-height: 500px;
  margin-bottom: 10px;
}


.manage-panel-attributes-right {
  display: inline-block;
  width:48%;   
  border: 1px solid black;
  border-radius: 4px; 
  vertical-align: top;
  min-height: 500px;
  margin-bottom: 10px;
  overflow: scroll;
  max-height: 500px;
}




.mint-button:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.mint-button {
  width:360px;
  height:70px;
  color:black;
  background-color: #e9cf69;
  /* border: 3px solid black; */
  border-radius: 60px;
  margin-left: auto;
  margin-right: auto;
  font-size: 2em;  
  cursor: pointer;  
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
  padding-top: 20px;  
  transition: transform 0.2s ease; /* Smooth transition */
}

.mint-button.toggle {
  width:90px;
  display: inline-block;

}
.mint-button-number {
  font-size: 3em;
  font-weight: bold;
  display: inline-block;
  width: 140px;
}


.web-avatar {
  width: 200px;
  border-radius: 200px;
  border: 18px solid;
  border-color: beige;
  animation-name: backgroundColorPalette;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  margin:12px;
}

@keyframes backgroundColorPalette {
  0% {
    border-color: #ee6055;
  }
  25% {
    border-color: #60d394;
  }
  50% {
    border-color: #aaf683;
  }
  75% {
    border-color: #ffd97d;
  }
  100% {
    border-color: #ff9b85;
  }
}

.floating { 
  animation-name: floating;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes floating {
  0% { transform: translate(0,  0px); }
  50%  { transform: translate(0, 10px); }
  100%   { transform: translate(0, -0px); }   
}



.web-avatar-square {
  width: 200px;
  /* border-radius: 200px; */
  /* border: 6px solid; */
  /* border-color: beige; */
  margin:0px;
  vertical-align: top;
  border-radius: 12px;
  padding: 3px;
}

.total-supply {
  font-weight: 500;
  font-size: 2em;
}


.top-bar {
  padding-top:30px; 
  margin-bottom: 5px;
  height: 75px;
}

.top-bar-rainbow {
  position:absolute;
  left: -1000px;
  top: -100px;
  z-index: 0;
}


.top-bar-right {
  float:right;
  padding-right: 30px;
}

.top-bar-menu-item {
  display: inline-block;  
  margin-left:10px;
  margin-right:10px;
  vertical-align: top;  
}

.top-bar-logo {
  height: 60px;
  float: left;  
  padding-left: 140px;
  padding-right: 40px;
  z-index: 10;
  cursor: pointer;
}

.connect-wallet-button {
  width:220px;
  height:40px;
  color: white;
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px;
  display: inline-block;
  background-color: #426bad;
  overflow: hidden;
  padding: 14px;
  cursor: pointer;
  margin-left: 50px;
  margin-right: 30px;
  border-left: 1px solid #2935b6;
  border-right: 1px solid #0e2a76;
  border-bottom: 1px solid #0e2a76;
  margin-bottom: 10px;
}

.connect-wallet-button-connected {
  vertical-align:top;
  padding-top:0px;
  display:inline-block;
  text-align: left;
  max-width: 140px;
  text-overflow: ellipsis;
  overflow: hidden; 
}


.manage-sign-in-button {
  font-size: 20px;
  font-weight: 500;
  color: white;
  padding-top: 17px;
  background-color: #357dcf;
  border: 1px solid #4b99e2;
  border-radius: 60px;
  height: 60px;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;

}

.manage-sign-in-button-info {
  font-size: 12px;
}

.manage-sign-in-button:hover {
  background-color: #5cb5df;
}


.web-block-mint {
  padding-top: 70px;
}

.web-block-center {
  padding:2em;
  margin-left: auto;
  margin-right: auto;  
}

.max-width {
  max-width: 580px;
}

.web-block.avatars {
  padding-bottom: 120px;
}

.web-block.who {
  background: linear-gradient(to bottom, #71cbff, #71cbff);
  max-width: none;
  padding-left: 1.6em;
  padding-right: 1.6em;
  color: black;
  font-weight: bold;  
  text-align: left;
}

.full-screen-3d-logo {
  margin-left: auto;
  margin-right: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
  /* color:darkred; */

  pointer-events: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently */


}

.full-screen-logo {
  max-width: 260px;
}


.lds-dual-ring {
  display: block;
  width: 80px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
}


.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.full-screen-loading {
  background-color: black;
  width: 100vw;
  height: 100vh;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently */
}



.fade-out {
  overflow: hidden;
  animation-name: fadeOut;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

@keyframes fadeOut {
  0% {
    opacity:1;
  }
  25% {
    opacity:.75;
  }
  50% {
    opacity:0.5;
  }
  75% {
    opacity:0.25;
  }
  99% {
    width: 100vw;
    height: 100vh; 
  }
  100% {
    opacity:0;
    width: 0;
    height:0;
  }
}

.menu-bar-3d-logo {
  position:absolute;
  top:10px;
  left:0;
}

.menu-bar-3d-button {
  width:418px;
  height:98px;
  border: 3px solid white;
  background-color: rgba(245, 245, 245, 0.408);

  border-radius: 60px;
  margin-left: 40px;
  margin-right: auto;
  font-size: 2em;  
  cursor: pointer;  
  margin-top: 100px;
  color:black;  
  padding-top:50px;
  font-weight:bold;
  display:inline-block;
}

.menu-bar-3d-button:hover {
  color:black;
  background-color:white;
  -webkit-transition: background-color 500ms linear;
  -ms-transition: background-color 500ms linear;
  transition: background-color 500ms linear;
}


.menu-bar-3d-button.enter-vr{
  /* position:absolute; */
  /* top:10px; */
  /* left:0; */
  z-index:5;
}

.menu-bar-group {
  position:absolute;
  top:0;
  min-height: 100vh;
  /* padding: 150px 48px 0px; */
  display: flex;
  flex-direction: column;
  -moz-box-pack: justify;
  justify-content: space-between;
}


@media (min-width: 1100px) {
  .desktop-hide {
    visibility: hidden;
    display: none !important;
  }
}

@media (max-width: 1100px) {

  .web-block-center {
    width: 100%;
    max-width: none;    
    padding:0;
    padding-top: 2em;
    padding-bottom: 2em;
  }


  .menu-bar-3d-group {
    margin-top:100px;
  }

  .menu-bar-3d-button {
    width:200px;
    height:40px;
    background-color: rgba(245, 245, 245, 0.408);
    border: 3px solid white;
    border-radius: 60px;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.4em;  
    cursor: pointer;  
    margin-top: 10px;
    margin-bottom: 10px;  
    color:black;  
    padding-top:10px;
    font-weight:bold;
    display:inline-block;
  }
  
  .desktop-hide {
    visibility: visible;
    display: inline-block;
  }
  .mobile-hide {
    visibility: hidden;
    display: none !important;
  }

  .top-bar {
    padding-top: 20px;
    padding-bottom: 10px;
  }

  .top-bar-logo {
    height: 65px;
    float: none;  
    padding-left: 0px;
  }
  

  .web-block-left {
    width: 98vw;
    padding: 0;
    margin: 0;
  }

  .web-block-right {
    width: 98vw;
    padding: 0;
    margin: 0;
  }
}

.top-bar-icon {
  height:30px !important;  
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) hue-rotate(90deg) brightness(.2);
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes popIn {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  70% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.web-avatar-square {
  /* scale: 0; */
  opacity: 0;
  animation: popIn 0.35s forwards; /* Adjust duration as needed */
}


.mint-button-number {
  display: inline-block; /* Allow the element to be animated */
  transition: transform 0.2s ease-in-out; /* Smooth transition for scaling */
}

.bulge {
  animation: bulge 0.2s; /* Duration of the bulge animation */
}

@keyframes bulge {
  0% {
      transform: scale(1);
  }
  50% {
      transform: scale(1.25); /* Scale up */
  }
  100% {
      transform: scale(1); /* Scale back to normal */
  }
}